import React from 'react';

import { Building, CreateBuilding } from './building';
import { BuildingData } from './settings';
import { Settings } from './settings';
import { VillageContext } from './village-context';


export class Buildings extends React.Component {

  static contextType = VillageContext;

  constructor(props, context) {
    super(props, context);
    this.context.buildings = this.generate();
  }

  generate = () => {
    let buildings = [];
    BuildingData.forEach( entry => {
      let newBuilding = CreateBuilding(entry, this.context.stores);
      buildings.push(newBuilding);
    });
    return buildings;
  };

  render(props) {
    return <div className="wrapperNarrow">
      <div className="buildingContainer">
        <h3>Buildings</h3>
        { this.context.buildings.map( building =>
          <Building
            key={building.id}
            building={building}
            manageVillagerAssignments={this.props.manageVillagerAssignments}
            increasePriority={this.props.increasePriority}
            decreasePriority={this.props.decreasePriority}
          />
        )}
      </div>
    </div>;
  }
}


