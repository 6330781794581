import React from 'react';

import { Log, LogManager } from './log';
import { Score } from './score';
import { Settings } from './settings';
import { VillageContext } from './village-context';
import { VillageManagement } from './village-management';

export class World extends React.Component {

  static contextType = VillageContext;

  constructor(props) {
    super();
    this.state = {
      score: 0,
      log: LogManager,
    };
  }

  manageProductionBuildings = () => {
    this.context.buildings.forEach( building => building.manage());
  };

  manageEvents = () => {
    if (Math.floor(Math.random() * 100) === 5) {
      const date = new Date();
      this.state.log.update(date.getTime() + ' event placeholder', 'environment');
    }
  }

  checkGameEndConditions = () => {
    const villagers = this.context.villagers.slice();
    let everyoneIsDead = true;
    villagers.map( villager => villager.assignment === 'DEAD' && everyoneIsDead ? everyoneIsDead = true : everyoneIsDead = false);
    everyoneIsDead ? this.state.log.update("dead... all dead.", 'villager') : null;
    this.context.gameOver = everyoneIsDead;
  }

  tick = () => {
    this.manageProductionBuildings();
    this.manageEvents();
    this.checkGameEndConditions();
    if (this.context.gameOver) {
      clearInterval(this.timer);
    }
    this.setState({
      score: this.state.score + 1,
      elapsed: this.state.elapsed + 1,
    });
  };
  componentDidMount = () => {
    if (!Settings.paused) {
      this.timer = setInterval(() => this.tick(), Settings.interval);
    }
  };
  // prevent memory leak
  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  render = () => {
    return (
      <div className="village">
        <Score score={this.state.score} />
        <VillageManagement />
        <div className="wrapper">
          <Log logType='production' />
          <Log logType='consumption' />
          <Log logType='environment' />
          <Log logType='villager' />
        </div>
      </div>
    )
  }
};
