import React from 'react';

import { Goods } from './goods';
import { LogManager } from './log';
import { Materials } from './materials';
import { Settings } from './settings';

export class Building extends React.Component {

  generateStyleString = (value) => {
    const progressColor = this.props.building.producing ? 'lightgreen' : 'lightSalmon';
    const noProgressColor = 'white';
    return 'linear-gradient(to right, '
      + progressColor + ' 0%, '
      + progressColor + ' ' + value + '%, '
      + noProgressColor + ' ' + value + '%, '
      + noProgressColor + ' 100%)'
  };

  render() {
    var building = this.props.building;
    var progressStyle = {
      background: this.generateStyleString(building.progress)
    };
    return (
      <div className={'buildingCard cardRow ' + (building.producing ? "active" : "inactive")} style={progressStyle}>
        <div className="wrapper">
          <div>
            {building.assignedVillagers.length} - <span className="buildingLabel">{building.label}</span>
          </div>
          <div>
            { building.goods ? <Goods building={building} /> : null }
            { building.materials ? <Materials materials={building.materials} /> : null }
          </div>
          <div>
            <button type="button" className="villagerButton" id="removeAVillger" onClick={() => this.props.manageVillagerAssignments(building, 1, 'unassign')}>-</button>
            <button type="button" className="villagerButton" id="addAVillager" onClick={() => this.props.manageVillagerAssignments(building, 1, 'assign')}>+</button>
          </div>
        </div>
      </div>
    );
  }
}

export const CreateBuilding = (entry, stores) => {
  let buildingObj = {
    stores: stores,
    type: entry.processType,
    id: entry.id,
    priority: entry.id,
    name: entry.name,
    label: entry.label,
    description: entry.description,
    goods: entry.goods,
    materials: entry.materials,
    assignedVillagers: [],
    capacity: Settings.defaultCapacity,
    progress: 0,
    baseProductionRate: Settings.defaultProductionRate,
    producing: false,
    canProduce() {
      let flag = true;
      // FIXME: dictionary would be better, this is n^2
        // also would return true for duplicate input items with only 1 item available
      if (this.materials) {
        this.materials.forEach( material => {
          this.stores[material].count > 0 && flag ? flag = true : flag = false;
        });
      }
      return flag;
    },
    processProductionCycle() {
      const multiplier = this.getProductionMultiplier();
      // if multiplier is 0, we're not producing
      if (multiplier === 0) {
        this.producing = false;
        return;
      }
      this.manageBuildingMaterials();
      this.updateProductionStatus(multiplier);
    },
    getProductionMultiplier() {
      let multiplier = 0;
      this.assignedVillagers.map(villager =>
        multiplier += villager.hasTool
          ? Settings.toolMultiplier
          : 1
      );
      return multiplier;
    },
    manageBuildingMaterials() {
      if (this.materials && !this.materialsInUse) {
        this.useMaterials();
      };
    },
    useMaterials() {
      this.materials.map(
        material => this.stores[material] && this.stores[material].count > 0
              ? (this.stores[material].count -= 1, this.setBadThingClass(this.stores[material], true))
              : null
      );
      this.materialsInUse = true;
      LogManager.update(this.materials.map(material => material + " -1 " ), 'production')
    },
    setBadThingClass(store, state) {
      store.classNames.badThingHappened = state;
      store.classNames.goodThingHappened = !state;
    },
    updateProductionStatus(multiplier) {
      this.progress += this.baseProductionRate * multiplier;
      this.degradeTools();
      this.producing = true;
    },
    degradeTools() {
      this.assignedVillagers.map(villager => {
        villager.hasTool && villager.toolDurability > 0
          ? villager.toolDurability -= this.varyDecayRate(Settings.toolDegradeRate)
          : null;
        villager.hasTool && villager.toolDurability <= 0
          ? villager.hasTool = false
          : null
      });
    },
    varyDecayRate(startingRate) {
      let min = startingRate - 0.05;
      let max = startingRate + 0.05;
      let adjustedDecay = Math.floor(Math.random() * (max - min) + min);
      return adjustedDecay;
    },
    completeProductionCycle() {
      this.progress -= Settings.productionComplete;
      if (!this.canProduce()) {
        this.producing = false;
        this.progress = 0;
        this.materialsInUse = false;
      }
      this.addProduct(this.goods);
    },
    addProduct() {
      this.goods.map(entry => {
        this.stores[entry].count += 1;
        this.setBadThingClass(this.stores[entry], false);
      });
      // count the elements in the goods array and stuff them in an object
        // then loop through the keys of the object and print the amounts produced
        // this is to enable production of more than one good per tick without printing duplicate good names
        // all of this will get rewritten when good stores get moved to the buildings
      let goodsMapping = {}
      this.goods.map( entry => Array.from(Object.keys(goodsMapping)).includes(entry) ? goodsMapping[entry] += 1 : goodsMapping[entry] = 1);
      LogManager.update(Array.from(Object.keys(goodsMapping)).map(entry => entry + " +" + goodsMapping[entry] + " "), 'production');
    },
    startNextProductionCycle() {
      if (this.materials && this.canProduce()) {
        this.useMaterials();
      }
    },
    manage() {
      if (this.canProduce() || this.materialsInUse) {
        this.processProductionCycle();
      };
      if (this.progress >= Settings.productionComplete) {
        this.completeProductionCycle();
        this.startNextProductionCycle();
      };
    }
  };
  return buildingObj;
}
