import React from 'react';

import { StatusRow } from './status-row';

export class Status extends React.Component {

  render(props) {
    const statuses = this.props.statuses;
    return <div className="statusContainer">
      <h3>Villager Status</h3>
      <div className="statuses">
        <div className="infos">
          {Array.from(Object.keys(statuses)).map( statusName =>
            statuses[statusName].count > 0
            ? <StatusRow key={statusName} statusName={statusName} statusProps={statuses[statusName]}/>
            : null
          )}
        </div>
      </div>
    </div>;
  }
}
