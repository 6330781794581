import React from 'react';

export class VillagerDetailsRow extends React.Component {

  render(props) {
    var classNames = require('classnames');
    this.props.deets.classNames
    const toolClassName = classNames('villagerDetailsCount', this.props.deets.toolless.classNames);
    const clothingClassName = classNames('villagerDetailsCount', this.props.deets.naked.classNames);
    const warmthClassName = classNames('villagerDetailsCount', this.props.deets.freezing.classNames);
    const energyClassName = classNames('villagerDetailsCount', this.props.deets.starving.classNames);
    const assignmentClassName = classNames('villagerDetailsName', this.props.deets.dead.classNames);

    return <tr>
      <td className={assignmentClassName}>{this.props.deets.assignment}</td>
      <td>{this.props.deets.id}</td>
      <td className={toolClassName}>{this.props.deets.toolDurability}</td>
      <td className={clothingClassName}>{this.props.deets.clothingDurability}</td>
      <td className={warmthClassName}>{this.props.deets.warmth}</td>
      <td className={energyClassName}>{this.props.deets.energy}</td>
      <td>{this.props.deets.age.toFixed(1)}</td>
    </tr>;
  }
}
