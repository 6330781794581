import React from 'react';

import { Message } from './message';
import { Settings } from './settings';

export const LogManager = {
  entries: {
    consumption: [],
    environment: [],
    production: [],
    villager: [],
  },
  maxSize: Settings.maxLogArraySize,
  update(message, msgType) {
    // insert message at start of array
    this.entries[msgType].unshift(message);
    // trim array to max log length
    this.entries[msgType].length > this.maxSize ? this.entries[msgType].pop() : null;
  },
  reset() {
    this.entries['consumption'] = [];
    this.entries['environment'] = [];
    this.entries['production'] = [];
    this.entries['villager'] = [];
  },
}

export class Log extends React.Component {

  render = () => {
    var classNames = require('classnames');
    const logType = this.props.logType;
    const logClassName = classNames('log', logType + 'Log');
    const headerText = logType.charAt(0).toUpperCase() + logType.slice(1);

    return <div className="info">
      <div className={logClassName}>
        <h2>{headerText} Log</h2>
        { LogManager.entries[logType].map( (logEntry,index) => <Message key={index} message={logEntry} /> ) }
      </div>
    </div>
  }
}
