import React from 'react';

export class Rules extends React.Component {

  render(props) {
    return <div className="rules">
      { this.props.rules.map(rule => <div key={Math.random(Date.now())}>{rule}</div> ) }
    </div>
  }
}
