import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import { LogManager } from './components/log';
import { Rules } from './components/rules';
import { Settings } from './components/settings';
import { StartingLog } from './components/settings';
import { World } from './components/world';


const init = () => {
  LogManager.reset();
  createGame();
}

const createGame = () => {
  // pass functions and data to a react class with thing={thing}
  // these will then be accessible in the target object by
  // calling `this.props.thing`
  // these are much like kwargs
  const Game = () => {
    return <div className="game">
      <World />
      <Rules rules={StartingLog} />
    </div>
  }

  ReactDOM.render(<Game />,
    document.getElementById('root')
  );
}

init();
