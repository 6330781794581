
import React from 'react';

export class Score extends React.Component {

  render = () => {
    return <div className="score">
      <div>Score</div><div>{ this.props.score }</div>
    </div>
  }
}
