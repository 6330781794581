import React from 'react';

export class StoreRow extends React.Component {
  
  render(props) {
    var classNames = require('classnames');
    const name = this.props.storeItemName;
    const storeItemProps = this.props.storeItemProps;
    const count = storeItemProps.count;
    const storeClassNames = storeItemProps.classNames;
    storeClassNames.badThing = storeItemProps.count <= 0 ? true : false;
    // classNames is a list of css class names that get passed to the DOM element
    const storeClassName = classNames('cardRow', storeClassNames);

    return <div className={storeClassName}>
      <div className="cardName">{name}</div>
      <div className="cardCount">{count}</div>
      <div className="clear"></div>
    </div>;
  }
}
