import React from 'react';

export class StatusRow extends React.Component {

  render(props) {
    var classNames = require('classnames');
    const name = this.props.statusName;
    const statusProps = this.props.statusProps;
    const count = statusProps.count;
    const statusClassNames = statusProps.classNames;
    statusClassNames.badThing = statusProps.count > 0 ? true : false;
    const statusClassName = classNames('cardRow', statusClassNames);
    
    return <div className={statusClassName}>
      <div className="cardName">{name}</div>
      <div className="cardCount">{count}</div>
      <div className="clear"></div>
    </div>;
  }
}
