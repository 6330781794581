import React from 'react';

export class Message extends React.Component {

  render(props) {
    return <div className="message">
      { this.props.message }
    </div>
  }
}
