import React from 'react';

export class Goods extends React.Component {
  render() {
    var building = this.props.building;

    var goods = {};
    building.goods.map( good => Array.from(Object.keys(goods)).includes(good) ? goods[good] += 1 : goods[good] = 1);
    return (
      <div className='goods'>
        <span>makes: </span>
        <span>{Array.from(Object.keys(goods)).map(good => good + ":" + goods[good] + " ")}</span>
      </div>
    );
  }
}
