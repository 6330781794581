import React from 'react';

import { Settings } from './settings';
import { StoreRow } from './store-row';
import { VillageContext } from './village-context';


export class Stores extends React.Component {

  static contextType = VillageContext;

  constructor(props, context) {
    super(props, context);
    this.generateStores();
  }

  generateStores = () => {
    Object.entries(Settings.defaultStores).forEach( ([key, value]) => {
      let newStore = {count: value, classNames: {goodThingHappened: false, badThingHappened: false}};
      this.context.stores[key] = newStore;
    });
  }

  render(props) {
    const stores = this.context.stores;
    return <div className="storeContainer">
      <h3>Stores</h3>
      <div className="stores">
        <div className="infos">
          { Array.from(Object.keys(stores)).map( storeItemName =>
            <StoreRow key={storeItemName} storeItemName={storeItemName} storeItemProps={stores[storeItemName]} />
          )}
        </div>
      </div>
    </div>;
  }
}
