import React from 'react';

import { Buildings } from './buildings';
import { LogManager } from './log';
import { Settings } from './settings';
import { Status } from './status';
import { Stores } from './stores';
import { VillageContext } from './village-context';
import { VillagerDetails } from './villager-details';


export class VillageManagement extends React.Component {
  static contextType = VillageContext;

  constructor(props, context) {
    super(props, context);
    this.initVillagerStatus(props);
    this.state = {
      elapsed: 0,
    };
  }

  initVillagers = () => {
    do {
      this.addVillager()
    }
    while (this.context.villagers.length < Settings.startingVillagers)
  }

  addVillager = () => {
    let nextId = this.context.villagers.length > 0 ? Math.max(...this.context.villagers.map(v => v.id)) + 1 : 0;
    let minAge = Settings.villagerStartAgeMin;
    let maxAge = Settings.villagerStartAgeMax;
    let startingAge = Math.floor(Math.random() * (maxAge - minAge) + minAge)
    const villager = {
      id: nextId,
      hasTool: Settings.villagerHasTool,
      toolDurability: Settings.toolDurability,
      assignment: Settings.defaultAssignment,
      sortField: Settings.defaultAssignment,
      warmth: Settings.defaultWarmth,
      energy: Settings.defaultEnergy,
      clothed: Settings.villagerClothed,
      ageDays: startingAge * Settings.daysInYear,
      age: startingAge,
      clothingDurability: Settings.clothingDurability,
      toolless: {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
      naked:    {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
      starving: {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
      freezing: {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
      dead:     {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
    };
    villager.clothe = () => {
      villager.clothingDurability = Settings.clothingDurability;
      villager.clothed = true;
    }
    villager.toolify = () => {
      villager.toolDurability = Settings.toolDurability;
      villager.hasTool = true;
    }
    this.context.villagers.push(villager);
    LogManager.update('added villager ' + villager.id, 'villager')
    return villager
  }

  sortVillagers = () => {
    if (this.state.elapsed % Settings.villagerSortInterval == 0) {
      this.context.villagers.sort((a, b) => (a.sortField.localeCompare(b.sortField) || a.id - b.id))
    }
  }

  updateTheBuildings = (building) => {
    const buildings = this.context.buildings.slice();
    const matchedBuildingIndex = buildings.findIndex(index => index.id === building.id);
    this.context.buildings = [
      ...buildings.slice(0, matchedBuildingIndex),
      building,
      ...buildings.slice(matchedBuildingIndex + 1),
    ];
  }

  initVillagerStatus = (props) => {
    this.villagerStatus = {
      naked:      {count: 0, classNameTimer: 0, classNames: {badThingHappened: false}},
      toolless:   {count: 0, classNameTimer: 0, classNames: {badThingHappened: false}},
      starving:   {count: 0, classNameTimer: 0, classNames: {badThingHappened: false}},
      freezing:   {count: 0, classNameTimer: 0, classNames: {badThingHappened: false}},
      unassigned: {count: 0, classNameTimer: 0, classNames: {badThingHappened: false}},
      dead:       {count: 0, classNameTimer: 0, classNames: {badThingHappened: false}},
    }
  }

  getUnassignedVillagers = () => {
    return this.context.villagers.filter( villager => villager.assignment === 'IDLE');
  }

  assignVillagers = (building, amount) => {
    if (building.capacity - building.assignedVillagers.length <= 0 || this.villagerStatus.unassigned === 0) {
      return;
    }

    // const allVillagers = this.context.villagers.slice();
    const selectedVillager = this.context.villagers.find( villager => villager.assignment === 'IDLE' && amount > 0)

    selectedVillager.assignment = building.name;
    selectedVillager.sortField = selectedVillager.assignment;
    building.assignedVillagers.push(selectedVillager);
    LogManager.update('villager ' + selectedVillager.id + ' assigned to ' + building.name, 'villager')

    this.updateTheBuildings(building);

    amount -= 1;
    if (amount > 0) {
      this.assignVillagers(building, amount);
    }
  }

  unassignVillagers = (building, amount) => {
    if (building.assignedVillagers.length === 0) {
      return;
    }

    const removedVillager = building.assignedVillagers.pop();
    removedVillager.assignment = 'IDLE';
    removedVillager.sortField = removedVillager.assignment;
    LogManager.update('villager ' + removedVillager.id + ' unassigned from ' + building.name, 'villager')

    this.updateTheBuildings(building);

    amount -= 1;
    if (amount > 0) {
      this.unassignVillagers(building, amount);
    }
  }

  manageVillagerAssignments = (building, amount, operation) => {
    if (operation === 'assign' && this.villagerStatus.unassigned.count > 0) {
      this.assignVillagers(building, amount);
    }
    if (operation === 'unassign') {
      this.unassignVillagers(building, amount);
    }
  };

  removeVillager = (villager) => {
    const buildings = this.context.buildings.slice();
    const targetBuilding = buildings.find( building => villager.assignment === building.name );
    const villagerIndex = targetBuilding.assignedVillagers.findIndex(assignedVillager => assignedVillager.id === villager.id);

    targetBuilding.assignedVillagers = [
      ...targetBuilding.assignedVillagers.slice(0, villagerIndex),
      ...targetBuilding.assignedVillagers.slice(villagerIndex + 1),
    ]
    this.updateTheBuildings(targetBuilding);
  }

  killVillager = (villager, reason) => {
    villager.assignment !== 'IDLE' ? this.removeVillager(villager) : null;
    villager.assignment = 'DEAD';
    villager.sortField = 'z_DEAD';
    this.setBadThingClass(villager, 'dead', true);
    LogManager.update('a villager has ' + reason + ' to death', 'villager');
  }

  manageVillagerConditions = () => {
    const villagerStatus = this.villagerStatus;
    villagerStatus.unassigned.count = 0;
    villagerStatus.toolless.count = 0;
    villagerStatus.naked.count = 0;
    villagerStatus.starving.count = 0;
    villagerStatus.freezing.count = 0;
    villagerStatus.dead.count = 0;

    const villagers = this.context.villagers.slice();
    let died = false;
    villagers.forEach( villager => {
      if (villager.assignment !== 'DEAD') {
        this.ageVillager(villager);
        this.checkVillagerLiveness(villager, villagerStatus);
        this.checkVillagerAssignment(villager, villagerStatus);
        this.checkVillagerTool(villager, villagerStatus);
        this.checkVillagerWarmth(villager, villagerStatus);
        this.manageVillagerClothing(villager, villagerStatus);
        this.checkVillagerEnergy(villager, villagerStatus);
      };
    });
    this.updateAllVillagers(villagers);
    this.updateVillagerStatus(villagerStatus);
  }

  ageVillager = (villager) => {
      villager.ageDays += 1;
      villager.age = villager.ageDays / Settings.daysInYear;
      let maxDeathAge = Settings.villagerMaxAge;
      let randomCheck = Math.pow(maxDeathAge - villager.age, Settings.villagerDeathPower);
      if (Math.random() < randomCheck) {
          let reason = 'old age';
          if (villager.age < Settings.villagerMaxAge - Settings.villagerMaxAgeVariance) {
              let reasons = ['an accident', 'diseased']
              reason = reasons[Math.floor(Math.random()*reasons.length)];
          }
          this.killVillager(villager, reason);
          return true
      }
      return false
  }

  checkVillagerLiveness = (villager, villagerStatus) => {
    this.villagerStatus.unassigned.count += villager.assignment === 'DEAD' ? 1 : 0;
  }

  checkVillagerAssignment = (villager, villagerStatus) => {
    this.villagerStatus.unassigned.count += villager.assignment === 'IDLE' ? 1 : 0;
  }

  checkVillagerTool = (villager, villagerStatus) => {
    villager.hasTool = villager.toolDurability > 0 ? true : false;
    if (villager.toolDurability < Settings.villagerWarningValue) {
      this.setBadThingClass(villager, 'toolless', true);
    }
    // only equip tools to villagers that are assigned to a building
    if (!villager.hasTool) {
      villager.assignment !== "IDLE" ? this.getTool(villager) : null;
      villagerStatus.toolless.count += 1;
    }
  }

  checkVillagerWarmth = (villager, villagerStatus) => {
    const baseWarmthDecayRate = this.varyDecayRate(Settings.baseWarmthDecayRate);
    const clothingWarmthDecayReduction = Settings.clothingWarmthDecayReduction;
    const villagerDeathValue = Settings.villagerDeathValue;
    villager.warmth -= villager.clothed ? baseWarmthDecayRate - clothingWarmthDecayReduction : baseWarmthDecayRate;
    villager.warmth < villagerDeathValue ? villager.warmth = villagerDeathValue : null;
    this.consumeFirewoodIfNeeded(villager);
    if (villager.warmth <= Settings.villagerWarningValue) {
      villagerStatus.freezing.count += 1;
      this.setBadThingClass(villager, 'freezing', true);
    } else {
      this.setBadThingClass(villager, 'freezing', false);
    }
    if (villager.warmth <= villagerDeathValue) {
      this.killVillager(villager, 'frozen');
      return true;
    }
    return false;
  }

  varyDecayRate = (startingRate) => {
    let min = startingRate - 0.05;
    let max = startingRate + 0.05;
    let adjustedDecay = Math.floor(Math.random() * (max - min) + min);
    return adjustedDecay;
  }

  consumeFirewoodIfNeeded = (villager) => {
    const warmthReplenishThreshold = Settings.warmthReplenishThreshold;
    if (villager.warmth >= warmthReplenishThreshold) {
      return;
    }
    this.getWarm(villager);
  }

  manageVillagerClothing = (villager, villagerStatus) => {
    const baseClothingDecayRate = this.varyDecayRate(Settings.clothingDegradeRate);
    villager.clothingDurability > 0 ? villager.clothingDurability -= baseClothingDecayRate: null;
    villager.clothingDurability <= 0 ? villager.clothed = false : null;
    if (villager.clothingDurability < Settings.villagerWarningValue) {
      this.setBadThingClass(villager, 'naked', true);
    }
    if (!villager.clothed) {
      this.getClothing(villager);
      villagerStatus.naked.count += 1;
    }
  }

  checkVillagerEnergy = (villager, villagerStatus) => {
    const baseEnergyDegradeRate = this.varyDecayRate(Settings.baseEnergyDegradeRate);
    // const baseEnergyDegradeRate = Settings.baseEnergyDegradeRate;
    const assignmentEnergyMultiplier = Settings.assignmentEnergyMultiplier;
    const villagerDeathValue = Settings.villagerDeathValue;
    const multiplier = villager.assignment !== "IDLE" ? assignmentEnergyMultiplier : 1;
    villager.energy -= baseEnergyDegradeRate * multiplier;
    villager.energy < villagerDeathValue ? villager.energy = villagerDeathValue : null;
    this.consumeFoodIfNeeded(villager);
    if (villager.energy <= Settings.villagerWarningValue) {
      villagerStatus.starving.count += 1;
      this.setBadThingClass(villager, 'starving', true);
    } else {
      this.setBadThingClass(villager, 'starving', false);
    }
    if (villager.energy <= villagerDeathValue) {
      this.killVillager(villager, 'starved');
      return true;
    }
    return false;
  }

  getClothing = (villager) => {
    const targetType = 'clothing'
    const targetStore = this.context.stores[targetType];
    const amount = 1
    if (targetStore.count >= amount) {
      villager.clothe()
      targetStore.count -= amount;
      targetStore.classNames.badThingHappened = true
      this.setBadThingClass(villager, 'naked', false);
      LogManager.update('villager ' + villager.id + ' ' + targetType + ' ' + amount, 'consumption');
    }
  }

  getFood = (villager) => {
    const targetType = 'food'
    const targetStore = this.context.stores[targetType];
    const amount = 1
    if (targetStore.count >= amount) {
      villager.energy += Settings.energyPerFood;
      targetStore.count -= amount;
      targetStore.classNames.badThingHappened = true
      LogManager.update('villager ' + villager.id + ' ' + targetType + ' ' + amount, 'consumption');
      this.setBadThingClass(villager, 'starving', false);
    }
  }

  getTool = (villager) => {
    const targetType = 'tool'
    const targetStore = this.context.stores[targetType];
    const amount = 1
    if (targetStore.count >= amount) {
      villager.toolify()
      targetStore.count -= amount;
      targetStore.classNames.badThingHappened = true
      this.setBadThingClass(villager, 'toolless', false);
      LogManager.update('villager ' + villager.id + ' ' + targetType + ' ' + amount, 'consumption');
    }
  }

  getWarm = (villager) => {
    const targetType = 'firewood'
    const targetStore = this.context.stores[targetType];
    const amount = 1
    if (targetStore.count >= amount) {
      villager.warmth += Settings.warmthPerFirewood;
      targetStore.count -= amount;
      targetStore.classNames.badThingHappened = true
      LogManager.update('villager ' + villager.id + ' ' + targetType + ' ' + amount, 'consumption');
    }
  }

  consumeFoodIfNeeded = (villager) => {
    const energyReplenishThreshold = Settings.energyReplenishThreshold;
    if (villager.energy >= energyReplenishThreshold) {
      return;
    }
    this.getFood(villager);
  }

  updateAllVillagers = (villagers) => {
  }

  updateVillagerStatus = (villagerStatus) => {
    this.setState({
      villagerStatus: this.villagerStatus,
    });
  }

  // refactor to setBadThingClassVillager
  setBadThingClass = (villager, condition, state) => {
    this.villagerStatus[condition].classNames.badThingHappened = state;
    villager[condition].classNames.badThingHappened = state;
    villager[condition].classNames.badThing = state;
    this.villagerStatus[condition].classNames.goodThingHappened = !state;
    villager[condition].classNames.goodThingHappened = !state;
  }

  resetStatusClassNames = () => {
    Array.from(Object.keys(this.villagerStatus)).map( key => {
      this.villagerStatus[key].classNames.goodThingHappened = false;
      this.villagerStatus[key].classNames.badThingHappened = false;
    });
  }
  handlePopulation = () => {
    if (this.state.elapsed % Settings.villagerAddInterval == 0) {
      let newVillager = this.addVillager();
      let min = Settings.villagerAddIntervalMin;
      let max = Settings.villagerAddIntervalMax;
      Settings.villagerAddInterval = Math.floor(Math.random() * (max - min) + min)
    }
  };

  tick = () => {
    this.resetStatusClassNames()
    this.manageVillagerConditions();
    if (this.context.gameOver) {
      clearInterval(this.timer);
    }
    this.setState({
      elapsed: this.state.elapsed + 1,
    });
    this.handlePopulation();
    this.sortVillagers();
  };
  componentDidMount = () => {
    if (!Settings.paused) {
      this.timer = setInterval(() => this.tick(), Settings.interval);
    }
    this.initVillagers();
  };
  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  render = () => {
    const statuses = this.villagerStatus;
    return <div className="wrapper">
      <Buildings buildings={this.context.buildings}
        manageVillagerAssignments={this.manageVillagerAssignments}
        increasePriority={this.increasePriority}
        decreasePriority={this.decreasePriority}
      />
      <div className="wrapper">
        <Stores />
        <Status statuses={statuses} />
        <VillagerDetails villagers={this.context.villagers} />
      </div>
    </div>
  }
}
