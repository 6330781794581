const defaultDurability = 250

export const Settings = {
  interval: 50,
  paused: false,
  maxLogArraySize: 20,

  startingVillagers: 15,

  daysInYear: 100,

  villagerSortInterval: 50,
  villagerAddInterval: 10,
  villagerAddIntervalMin: 500,
  villagerAddIntervalMax: 1000,
  villagerDeathValue: -50,
  villagerWarningValue: 20,
  villagerStartAgeMin: 17,
  villagerStartAgeMax: 30,
  villagerMaxAge: 90,
  villagerMaxAgeVariance: 25,
  villagerDeathPower: -2,

  villagerHasTool: true,
  toolMultiplier: 4,
  toolDurability: defaultDurability,
  toolDegradeRate: 1,

  villagerClothed: true,
  clothingDegradeRate: 1,
  clothingWarmthDecayReduction: 2,
  clothingDurability: defaultDurability,
  baseWarmthDecayRate: 3,
  warmthReplenishThreshold: 0,
  warmthPerFirewood: defaultDurability - defaultDurability * 0.1,
  defaultWarmth: defaultDurability,
  defaultAssignment: 'IDLE',

  baseEnergyDegradeRate: 1,
  assignmentEnergyMultiplier: 2,
  energyReplenishThreshold: 0,
  energyPerFood: defaultDurability - defaultDurability * 0.1,
  defaultEnergy: defaultDurability,

  productionComplete: 100,
  defaultCapacity: 50,
  defaultProductionRate: 1,

  defaultStores: {
    'food': 100,
    'firewood': 100,
    'wood': 100,
    'iron': 100,
    'tool': 100,
    'hide': 100,
    'clothing': 100,
  },
}

export const StartingLog = [
  "Generate resources to keep your villagers alive.",
  "Food prevents starvation.",
  "Firewood prevents freezing.",
  "Clothing staves off freezing longer.",
  "Tools significantly increase production rate.",
  "If a villager is freezing or starving for too long, the villager dies.",
]

export const BuildingData = [
  {
    id: 10,
    name: 'FORESTER_HUT',
    label: "Forester's Hut",
    description: "it's big, it's heavy, it's wood",
    goods: ['wood'],
    processType: 'extraction',
  },
  {
    id: 20,
    name: 'GATHERER_HUT',
    label: "Gatherer's Hut",
    description: "nuts, berries, and tubers are packed with nutrients",
    goods: ['food', 'food', 'food'],
    processType: 'extraction',
  },
  {
    id: 30,
    name: 'HUNTER_LODGE',
    label: "Hunter's Lodge",
    description: "take advantage of nature's bounty",
    goods: ['food', 'hide'],
    processType: 'extraction',
  },
  {
    id: 40,
    name: 'MINE',
    label: "Mine",
    description: "the strength of the earth to forge the tools",
    goods: ['iron'],
    processType: 'extraction',
  },
  {
    id: 110,
    name: 'WOODCUTTER',
    label: 'Woodcutter',
    description: "it's big, it's heavy, it's wood",
    materials: ['wood'],
    goods: ['firewood', 'firewood'],
    processType: 'production',
  },
  {
    id: 100,
    name: 'SMITHY',
    label: 'Smithy',
    description: 'workers are more efficient with the right tools',
    materials: ['wood', 'iron'],
    goods: ['tool'],
    processType: 'production',
  },
  {
    id: 120,
    name: 'TAILOR',
    label: 'Tailor',
    description: "clothing to keep warm",
    materials: ['hide'],
    goods: ['clothing'],
    processType: 'production',
  },
];
