import React from 'react';

import { Settings } from './settings';
import { VillagerDetailsRow } from './villager-details-row';

export class VillagerDetails extends React.Component {

  render(props) {
    const villagers = this.props.villagers;
    if (!villagers) {
        console.log('no villagers')
        return <div className="villagerDetailsContainer">
          <h3>Villager Details</h3>
        </div>;
    }
    return <div className="villagerDetailsContainer">
      <h3>Villager Details</h3>
      <div className="infos">
        <div>
          <table>
          <tbody>
            <tr><th>assignment</th><th>ID</th><th>tool</th><th>clothes</th><th>warmth</th><th>energy</th><th>age</th></tr>
            {Array.from(Object.keys(villagers)).map( villagerId =>
            <VillagerDetailsRow key={villagerId} deets={villagers[villagerId]} />
            )}
          </tbody>
          </table>
        </div>
      </div>
    </div>;
  }
}

export const CreateVillager = (entry, stores) => {
  let villagerObj = {
    id: nextId,
    hasTool: Settings.villagerHasTool,
    toolDurability: Settings.toolDurability,
    assignment: Settings.defaultAssignment,
    sortField: Settings.defaultAssignment,
    warmth: Settings.defaultWarmth,
    energy: Settings.defaultEnergy,
    clothed: Settings.villagerClothed,
    ageDays: startingAge * Settings.daysInYear,
    age: startingAge,
    clothingDurability: Settings.clothingDurability,
    toolless: {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
    naked:    {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
    starving: {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
    freezing: {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
    dead:     {classNames: {badThing: false, badThingHappened: false, goodThingHappened: true}},
  };
}
