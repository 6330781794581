import React from 'react';

export class Materials extends React.Component {
  render() {
    return (
      <div className='materials'>
        <span>uses: </span>
        <span>{this.props.materials.toString()}</span>
      </div>
    );
  }
}
